import React from "react";
import "../styles/Footer.scss";
const Footer = () => {
  return (
    <>
      <footer>
        <div>
          <p>Wild Code School 2022</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
